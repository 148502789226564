@use "../config/" as *;
.waf-news-listing {
  @extend %white-bg;
  .filter-section {
    padding-inline: 0;
    .filter-title {
      @extend %d-none;
    }
    input {
      border-bottom: none;
    }
  }
  &.waf-component {
    margin: 0 var(--half-space-negative);
    @extend %p-zero;
  }
  .layout-wrapper {
    @extend %m-x-full;
  }
  .waf-head {
    @extend %m-b-two-space;
  }
  .article {
    &-list {
      gap: var(--one-n-half-space);
      overflow: visible;
      @extend %m-t-two-space;
    }
    &-item {
      border-radius: var(--half-radius);
      margin-block: unset;
      box-shadow: 0 .4rem 1.6rem 0 rgba(78, 78, 78, 0.10);
    }
    &-content {
      padding: var(--three-fourth-space) var(--full-space);
      a {
        position: static;
        &::after {
          content: "";
          width: unset;
          aspect-ratio: unset;
          transform: unset;
          @include position-combo(inset);
        }
      }
    }
    &-info .tagline {
      a,
      p {
        @extend %c-orange-10;
      }
    }
    &-title {
      height: auto;
      margin-block: 0 var(--quarter-space);
      color: var(--c-blue);
      font-weight: 400;
    }
    &-meta {
      margin-top: unset;
      flex-direction: row;
      .meta:not(:last-child)::after {
        content: "|";
        margin-inline: var(--one-third-space);
        display: inline-block;
        @extend %c-blue-1-10;
      }
      .meta-date {
        padding-right: 0;
        @extend %c-blue-1-10;
      }
    }
    &-readmore {
      @extend %d-none;
    }
  }
  .waf-child-wrapper {
    @extend %d-none;
  }
  &.waf-listing {
    .article-meta {
      display: block;
      .meta {
        position: relative;
        margin-left: 0;
        padding-left: 0;
        &:not(:last-child) {
          &::after {
            content: '|'; 
            margin-inline: .4rem;
            @extend %c-black-6;
           }
        }
      }
      .meta-date {
        &:before {
          content: unset;
        }
      }
    }
  }
  &.horizontal-list .article-thumbnail {
    .img-box {
      height: auto;
      aspect-ratio: 2/3;
      padding-bottom: 0;
    }
    img {
      object-position: top;
      image-rendering: auto;
    }
  }
}
@media (min-width: $tablet-min-width) {
  .waf-news-listing {
    @include white-bg();
    .layout-wrapper {
      padding-bottom: 17rem;
      margin-inline: auto;
    }
    .article {
      &-list {
        flex-direction: row;
        flex-wrap: wrap;
      }
      &-item {
        width: calc(50% - var(--three-fourth-space));
        border: .1rem solid transparent;
        transition: border-color .4s ease-in;
        padding-block: 1.2rem;
        &:hover {
          border: .1rem solid var(--c-light-grey);
        }
      }
      &-content {
        padding: var(--half-space) 10% var(--half-space) var(--full-space);
        a {
          &::before {
            height: 2.4rem;
            right: var(--full-space);
            background-color: var(--c-blue-2);
            color: var(--c-white);
            @include icon("\e801", 26);
            line-height: 1;
          }
        }
      }
      &-info {
        margin-bottom: var(--half-space);
      }
      &-meta {
        .meta {
          font-size: 1.6rem;
        }
      }
    }
    &.horizontal-list .article-thumbnail .img-box {
      aspect-ratio: 3/2;
    }
  }
}
@media (min-width: $desktop-min-width) {
  .waf-news-listing {
    .article-content {
      a::before {
        right: var(--two-space);
      }
    }
  }
}