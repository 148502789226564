@use '../config/' as *;
@forward '../listing/listing';
@forward '../listing/more-news';
%subtitle {
  line-height: 1.2;
  margin-block: 0 var(--one-n-half-space);
  @extend %m-b-one-n-half;
  @extend %c-blue-2-10;
  @extend %font-20;
  @media screen and (min-width: $tablet-min-width) {
    font-size: 2.4rem;
    line-height: 1.33;
  }
}
%one-image {
  @extend %m-b-two-space;
  .image {
    display: block;
    object-fit: cover;
    @extend %w-100;
  }
}
%flag-label-styles {
  .card,
  .meta {
    @extend %flex-n-c;
    @extend %gap-half;
    &-meta {
      @extend %flex-n-c;
      @extend %gap-half;
    }
    &-flag {
      width: 1.5rem;
      min-width: 1.5rem;
      aspect-ratio: 1;
      border: .1rem solid var(--c-white);
      @extend %circle-radius;
    }
    &-label {
      @extend %c-grey-10;
    }
  }
  @media screen and (min-width: $tablet-min-width) {
    .card-flag,
    .meta-flag {
      width: 2.4rem;
      min-width: 2.4rem;
    }
  }
}
%list-item-common {
  margin-bottom: 3.5rem;
  .item {
    @extend %p-full;
    @extend %relative;
    &:hover {
      box-shadow: 0 .4rem 1.6rem 0 hsl(var(--hsl-c-dark-liver) / .1);
      @extend %c-white-bg-1;
    }
    &:nth-child(even) {
      @extend %c-light-grey-1-bg-2;
      &:hover {
        @extend %c-bright-gray-bg-8;
      }
    }
    &-link {
      @include position-combo(inset);
      @extend %font-zero;
    }
    &.header {
      background: transparent;
      @extend %d-none;
      &:hover {
        background: transparent;
        box-shadow: unset;
        cursor: default;
      }
    }
  }
  .header .text {
    @extend %c-blue-2-10;
  }
  @media screen and (min-width: $tablet-min-width) {
    margin-bottom: 4.5rem;
    .item {
      padding-block: 2rem;
    }
    .header {
      padding-block: var(--full-space);
    }
    .item.header {
      display: flex
    }
    .text {
      @include left-separator();
    }
  }
}
img {
  @extend %d-block;
}
.waf-uww-inside-page {
  padding: 6rem 0;
}
.waf-news-listing {
  padding-block: var(--two-space);
  margin-bottom: 3.5rem;
  &.waf-listing {
    margin-inline: 0;
  }
  .first-list {
    margin-top: 0;
  }
}
.waf-inside-common {
  counter-reset: steps;
  .eyebrow {
    margin-bottom: 1.2rem;
    display: inline-block;
    line-height: 1.2;
    @extend %c-blue-1-10;
  }
  .body {
    margin-bottom: 3.5rem;
    line-height: 1.2;
    @extend %c-black-6;
    .link {
      font-family: $font-pb;
    }
    .link-normal {
      @extend %c-black-6;
    }
  }
  .body-2 {
    margin-bottom: 3.5rem;
    @extend %c-blue-2-10;
  }
  .bullet-list {
    margin-bottom: 3.5rem;
    @extend %p-l-full;
    &.list-style-none .list-item {
      list-style-type: none;
    }
    .bullet-list .bullet-list {
      margin-bottom: 0;
    }
  }
  .list {
    &-item {
      list-style-type: disc;
      @extend %c-black-6;
      .list-item {
        list-style-type: circle;
        .list-item {
          list-style-type: square;
        }
      }
    }
    &-text,
    &-title {
      line-height: 1.33;
      @extend %c-black-6;
      @extend %font-14;
    }
    &-title {
      margin-block: 0;
      font-family: $font-pb;
    }
    &-label {
      @extend %c-blue-1-10;
      @extend %font-20;
    }
  }
  .ol-list {
    margin-bottom: 3.5rem;
    @extend %p-l-full;
    &-item {
      list-style-type: decimal;
      @extend %c-black-6;
    }
    &-text {
      @extend %c-black-6;
    }
  }
  .subtitle {
    font-weight: 400;
    @extend %subtitle;
  }
  .title {
    margin-block: 0 4.5rem;
    line-height: 1.5;
    @extend %c-blue-10;
    @extend %font-24-pb;
  }
  .subtitle-bold {
    font-family: $font-pb;
    @extend %subtitle;
  }
  .download-pdf {
    @extend %m-b-one-n-half;
    @extend %flex-column;
    @extend %gap-half;
    .file {
      @extend %flex;
      @extend %gap-half;
      &-type {
        height: 1.8rem;
        padding-inline: .5rem;
        border-radius: .5rem;
        @extend %c-orange-bg-10;
        @extend %c-pure-white-10;
        @extend %font-12-pb;
      }
      &-name {
        line-height: 1.2;
        @extend %c-black-6;
      }
    }
    .btn-download {
      width: fit-content;
      margin-left: 4.2rem;
      @extend %flex-n-c;
      @extend %c-orange-10;
      @extend %font-14-pb;
      @extend %gap-half;
      &::after {
        content: "\e809";
        font: 400 2.4rem/1 $font-icon;
      }
    }
  }
  .copylink {
    margin-bottom: 3.5rem;
    @extend %flex-sb-c;
    @extend %gap-full;
    .text {
      @extend %c-black-6;
    }
    .btn-copy {
      font-size: 0;
      &::after {
        content: "\E85C";
        font: 2.4rem/1 $font-icon;
        @extend %c-orange-10;
      }
    }
  }
  .main-link {
    margin-bottom: 3.5rem;
    .link {
      @extend %c-orange-10;
      @extend %font-14-pb;
      &.normal {
        font-family: $font-pr;
      }
    }
  }
  .one-image,
  .one-image-portrait {
    @extend %one-image;
  }
  .one-image {
    &.w-auto {
      .image {
        max-width: 100%;
        width: auto;
        max-height: 31rem;
      }
    }
  }
  .one-image-portrait {
    max-width: 36rem;
    width: auto;
    max-height: 50rem;
  }
  .two-images {
    margin-bottom: 3.5rem;
    gap: var(--one-n-half-space);
    @extend %flex-column;
  }
  .video {
    margin-bottom: 3.5rem;
    .video {
      &-info {
        @extend %relative;
        &:hover {
          cursor: pointer;
          .video-title {
            @include position(null, null, 3rem, var(--one-n-half-space));
            @extend %c-white-10;
            @extend %font-20-pb;
            @extend %d-block;
          }
        }
      }
      &-poster {
        height: 50rem;
        background: linear-gradient(74deg, hsl(var(--hsl-c-dark-blue) / .7) 0%, hsl(var(--hsl-c-pure-black) / 0) 105.43%), var(--c-light-grey);
        object-fit: cover;
        @extend %w-100;
      }
      &-title {
        @extend %d-none;
      }
      &-play-icon {
        font-size: 0;
        @include position-combo(center);
        &::after {
          content: "\E814";
          font: 9.4rem/1 $font-icon;
          opacity: .8;
          filter: drop-shadow(0 0 2rem hsl(var(--hsl-c-pure-black) / .5));
          @extend %c-white-10;
        }
      }
      &-link {
        font-size: 0;
        @include position-combo(inset);
      }
    }
  }
  .yt-video {
    margin-bottom: 3.5rem;
    iframe {
      width: 100%;
      height: auto;
      aspect-ratio: 1.69;
    }
  }
  .card-job {
    padding: 4.5rem var(--one-n-half-space);
    border-radius: var(--half-space);
    box-shadow: 0 .4rem 1.6rem 0 hsl(var(--hsl-c-dark-liver) / .1);
    @extend %m-b-one-n-half;
    @extend %c-white-bg-10;
    .card {
      &-flag {
        &-wrap {
          margin-bottom: 1.8rem;
          @extend %flex-n-c;
          @extend %gap-half;
        }
        &-img {
          width: 3.3rem;
          min-width: 3.3rem;
          aspect-ratio: 1;
          @extend %circle-radius;
        }
        &-label {
          color: var(--c-blue-2);
          @include truncate-text(1);
          @extend %font-20;
        }
      }
      &-title {
        margin-block: 0 var(--one-n-half-space);
        line-height: 1.33;
        @extend %c-blue-10;
        @extend %font-24-pb;
      }
      &-content {
        @extend %m-b-one-n-half;
      }
      &-para {
        display: inline;
        line-height: 1.2;
        @extend %c-black-6;
      }
      &-more {
        font-weight: 700;
        @extend %c-orange-10;
      }
      &-apply {
        width: 15.7rem;
        height: 3.4rem;
        border: .1rem solid var(--c-blue);
        @extend %rounded-radius;
        @extend %flex-c-c;
        @extend %c-blue-10;
      }
    }
  }
  .card-mat {
    padding: var(--one-n-half-space) var(--full-space) 1rem;
    border-radius: var(--half-space);
    box-shadow: 0 .4rem 1.6rem 0 hsl(var(--hsl-c-dark-liver) / .1);
    @extend %m-b-one-n-half;
    @extend %c-white-bg-10;
    .card {
      &-logo-wrap {
        @extend %m-b-one-n-half;
      }
      &-logo-img {
        aspect-ratio: 2.91;
        object-fit: cover;
        @extend %w-100;
      }
      &-title {
        line-height: 1.2;
        margin-block: 0 var(--full-space);
        @extend %c-blue-10;
        @extend %font-20-pb;
      }
      &-info {
        padding-bottom: 1.8rem;
        border-bottom: .1rem solid var(--c-light-grey-1);
        @extend %c-black-6;
        > * {
          line-height: 1.5;
          @extend %d-block;
        }
      }
      &-link {
        font-family: $font-psb;
        @extend %c-orange-10;
      }
      &-meta-list {
        padding-top: 1rem;
      }
      &-meta-row:last-child .card-meta:last-child {
        border-bottom: unset;
      }
      &-meta {
        line-height: 1.5;
        padding-block: var(--half-space);
        border-bottom: .1rem solid var(--c-light-grey-1);
        @extend %font-12;
      }
      &-label {
        @extend %c-black-8;
      }
      &-value {
        @extend %d-block;
        @extend %c-black-4;
      }
    }
  }
  .profile-big {
    .card {
      &-item {
        padding: var(--one-n-half-space) 1.8rem;
        border: .15rem solid hsl(var(--hsl-c-pure-white) / .7);
        border-radius: var(--half-space);
        gap: var(--one-n-half-space);
        @extend %w-100;
        @extend %flex-n-c;
        @extend %relative;
        @extend %c-blue-bg-10;
        @extend %font-12;
        &:hover {
          box-shadow: 0 .4rem 1.6rem 0 hsl(var(--hsl-c-dark-liver) / .5);
        }
      }
      &-pic {
        width: 8.9rem;
        min-width: 8.9rem;
      }
      &-img {
        aspect-ratio: 1;
        border: .05rem solid hsl(var(--hsl-c-pure-white) / .7);
        object-fit: contain;
        @extend %circle-radius;
        @extend %d-block;
        @extend %c-light-grey-bg-10;
      }
      &-content {
        line-height: 1.5;
        flex-grow: 1;
        @extend %c-grey-10;
        > * {
          @include truncate-text(1);
        }
      }
      &-title {
        padding-bottom: 1.1rem;
        border-bottom: .1rem solid var(--c-blue-2);
        margin-block: 0;
        color: var(--c-ghost-white);
        @extend %font-18-psb;
      }
      &-location {
        padding-top: 1.1rem;
      }
      &-mail {
        z-index: var(--zindex1);
        @extend %relative;
        @extend %c-orange-10;
        @extend %font-12;
      }
      &-link {
        @include position-combo(inset);
        @extend %font-zero;
      }
    }
  }
  .profile-small {
    margin-bottom: 3.5rem;
    @extend %flag-label-styles;
    .card {
      &-item {
        padding: 2rem;
        border: .1rem solid hsl(var(--hsl-c-pure-white) / .7);
        border-radius: var(--half-space);
        @extend %w-100;
        @extend %flex-n-c;
        @extend %relative;
        @extend %c-blue-bg-10;
        @extend %gap-full;
        &:hover {
          box-shadow: 0 .4rem 1.6rem 0 hsl(var(--hsl-c-dark-liver) / .5);
        }
      }
      &-pic {
        width: 5rem;
        min-width: 5rem;
      }
      &-img {
        aspect-ratio: 1;
        border: .05rem solid hsl(var(--hsl-c-pure-white) / .7);
        object-fit: contain;
        @extend %circle-radius;
        @extend %d-block;
        @extend %c-light-grey-bg-10;
      }
      &-content {
        width: calc(100% - var(--full-space) - 5rem);
        @extend %c-grey-10;
      }
      &-name {
        margin-top: 0;
        color: var(--c-white);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @extend %font-16;
      }
      &-link {
        @include position-combo(inset);
        @extend %font-zero;
      }
    }
  }
  .card-contact {
    margin-bottom: 3.5rem;
    @extend %flex-column;
    @extend %gap-full;
    .card {
      &-item {
        padding: var(--half-space) var(--full-space);
        border-radius: var(--half-space);
        @extend %flex-n-c;
        @extend %c-light-grey-1-bg-4;
        @extend %gap-full;
      }
      &-meta {
        flex-grow: 1;
      }
      &-label {
        @extend %c-grey-10;
      }
      &-value {
        word-break: break-all;
        @extend %d-block;
        @extend %c-blue-10;
      }
      &-copy-btn {
        min-width: 2.4rem;
        @extend %font-zero;
        &::before {
          content: "\e85c";
          font: 2.4rem/1 $font-icon;
          @extend %c-orange-10;
        }
      }
    }
    .link {
      text-decoration: underline;
    }
  }
  .header-profile {
    margin-bottom: 3.5rem;
    @extend %flex-n-c;
    @extend %gap-full;
    @extend %flag-label-styles;
    .card {
      &-pic {
        width: 6rem;
        min-width: 6rem;
        height: 6rem;
      }
      &-img {
        object-fit: cover;
        @extend %h-100;
        @extend %circle-radius;
        @extend %d-block;
        @extend %c-light-grey-1-bg-5;
      }
      &-name {
        margin-block: 0 var(--half-space);
        line-height: 1.5;
        @extend %c-blue-10;
        @extend %font-24-pb;
      }
      &-label {
        font-size: 1.8rem;
      }
    }
  }
  .header-title {
    margin-block: var(--two-space) var(--one-n-half-space);
    @extend %font-24-pb;
  }
  .header-fed {
    margin-block: var(--two-space) var(--one-n-half-space);
    @extend %gap-full;
    @extend %flex-n-c;
    .card {
      &-flag {
        width: 4.8rem;
        min-width: 4.8rem;
        aspect-ratio: 1;
        @extend %circle-radius;
      }
      &-name {
        margin-block: 0;
        @extend %c-blue-10;
        @extend %font-24-pb;
      }
    }
  }
  .national-federations {
    margin-inline: var(--full-space-negative);
    @extend %list-item-common;
    .item {
      border-bottom: .1rem solid var(--c-light-grey-1);
      display: grid;
      gap: 1.2rem;
      justify-content: space-between;
      grid-template-areas: 'b b'
        'a c';
      grid-template-columns: 1fr auto;
      @extend %c-blue-10;
      &-flag {
        grid-area: a;
        @extend %flex-n-c;
        @extend %gap-half;
        &-img {
          width: 1.8rem;
          min-width: 1.8rem;
          aspect-ratio: 1;
          @extend %circle-radius;
          @extend %d-block;
        }
      }
      &-federation {
        margin-block: 0;
        font: 700 1.4rem $font-pb;
        grid-area: b;
      }
      &-style {
        grid-area: c;
        word-break: break-word;
      }
      &-country {
        @include truncate-text(1);
        @extend %font-12;
      }
    }
  }
  .courses-list {
    margin-inline: var(--full-space-negative);
    @extend %list-item-common;
    @extend %flag-label-styles;
    .item {
      gap: 0 var(--half-space);
      > * {
        vertical-align: middle;
        @extend %p-r-half;
        @extend %c-blue-2-10;
      }
    }
    .tournament {
      margin-block: 0;
      @extend %font-14;
    }
    .value {
      color: var(--c-blue-1);
    }
    .meta,
    .value {
      display: inline-flex;
      @extend %gap-half;
      &::before {
        content: "|";
        @extend %c-blue-1-10;
      }
    }
  }
  .federations-list {
    margin-inline: var(--full-space-negative);
    @extend %list-item-common;
    .item {
      border-bottom: .1rem solid var(--c-light-grey-1);
      @extend %c-blue-10;
    }
    .federation {
      margin-block: 0;
      @extend %font-16;
    }
  }
  .tech-assistance-list {
    margin-inline: var(--full-space-negative);
    @extend %list-item-common;
    @extend %flag-label-styles;
    .item {
      flex-wrap: wrap;
      gap: var(--half-space) 0;
      @extend %flex;
      @extend %c-blue-2-10;
    }
    .competition {
      margin-block: 0;
      flex-basis: 100%;
      @extend %font-14;
    }
    .value:last-child {
      display: inline-flex;
      @extend %c-blue-1-10;
      @extend %gap-half;
      &::before {
        content: "|";
        @extend %c-blue-1-10;
      }
    }
    .meta {
      @extend %p-r-half;
    }
    .meta-label {
      color: var(--c-blue-2);
    }
  }
  .councils-list {
    margin-inline: var(--full-space-negative);
    @extend %list-item-common;
    @extend %flag-label-styles;
    .item {
      padding-left: 6.8rem;
      border-bottom: .1rem solid var(--c-light-grey-1);
      flex-wrap: wrap;
      @extend %flex-sb-c;
      @extend %relative;
      @extend %c-blue-10;
      @extend %gap-half;
    }
    .member {
      &-meta {
        flex-basis: 100%;
      }
      &-img {
        width: 4.2rem;
        min-width: 4.2rem;
        height: 4.2rem;
        object-fit: cover;
        object-position: top;
        @include position(var(--full-space), null, null, var(--full-space));
        @extend %circle-radius;
        @extend %c-light-grey-bg-10;
      }
      &-label {
        font-family: $font-pb;
      }
    }
  }
  .documents-list {
    margin-inline: var(--full-space-negative);
    @extend %list-item-common;
    .name {
      margin-block: 0 1.2rem;
      @extend %c-blue-10;
      @extend %font-14;
    }
    .btn-download {
      @extend %c-orange-10;
      @extend %font-14-pb;
    }
  }
  .tournaments-list {
    margin-bottom: 3.5rem;
    @extend %flag-label-styles;
    .item {
      padding: 1.2rem var(--full-space);
      border-radius: .4rem;
      border: .05rem solid var(--c-blue-light);
      display: grid;
      gap: var(--half-space) var(--full-space);
      grid-template-areas: 'a b'
        'a c';
      grid-template-columns: 3.7rem auto;
      box-shadow: 0 .4rem 1.6rem 0 hsl(var(--hsl-c-dark-liver) / .1);
      line-height: 1;
      @extend %c-white-bg-10;
      &:not(:last-child) {
        @extend %m-b-half;
      }
    }
    .medal {
      width: 3.7rem;
      grid-area: a;
    }
    .name {
      grid-area: b;
      @extend %c-blue-2-10;
      @extend %font-16;
    }
    .metas {
      grid-area: c;
      @extend %flex-n-c;
      @extend %c-blue-10;
      > *:not(:first-child)::before {
        content: "|";
        @extend %m-x-half;
      }
    }
    .meta-label {
      color: var(--c-blue);
    }
  }
  .training-education {
    margin-inline: var(--full-space-negative);
    @extend %list-item-common;
    @extend %flag-label-styles;
    .item {
      flex-wrap: wrap;
      gap: var(--half-space) 0;
      @extend %p-y-full;
      @extend %flex;
      @extend %c-blue-1-10;
      > * {
        @extend %p-r-half;
      }
    }
    .course {
      margin-block: 0;
      flex-basis: 100%;
      @extend %c-blue-2-10;
      @extend %font-14;
    }
    .meta-label {
      @extend %c-blue-2-10;
    }
    .value,
    .meta {
      &::before {
        content: "|";
        @extend %m-r-half;
      }
    }
  }
  .member-banner {
    margin-inline: var(--full-space-negative);
    @extend %relative;
    @extend %flag-label-styles;
    .banner {
      &-head {
        @extend %relative;
        &::before,
        &::after {
          content: "";
          pointer-events: none;
          @include position-combo(inset);
        }
        &::before {
          background: hsl(var(--hsl-c-blue) / .2);
        }
        &::after {
          background: linear-gradient(hsl(var(--hsl-c-blue) / 0), hsl(var(--hsl-c-blue) / 1));
        }
      }
      &-thumb {
        object-fit: cover;
        aspect-ratio: 1.16;
        @extend %w-100;
        @extend %d-block;
      }
      &-body {
        height: 33.2rem;
        padding: 0 2rem 4.2rem;
        margin-top: -12rem;
        @extend %flex-column-fe-n;
        @extend %c-blue-bg-10;
      }
      &-footer {
        @include position(null, var(--one-n-half-space), 4.2rem);
      }
    }
    .name {
      margin-block: 0 var(--one-n-half-space);
      line-height: 1;
      @extend %relative;
      @extend %c-white-6;
      @extend %font-36-sb;
    }
    .lname {
      @extend %d-block;
      @extend %c-white-8;
    }
    .position {
      &::before {
        content: "|";
        margin-inline: .4rem;
      }
      &-list {
        margin-bottom: 3.6rem;
      }
      &-item {
        line-height: 1.5;
        @extend %c-white-6;
        @extend %font-12;
        &:not(:last-child) {
          margin-bottom: 1.2rem;
        }
      }
    }
    .org {
      color: var(--c-orange);
      font-family: $font-pb;
    }
    .item {
      gap: 2rem;
      @extend %flex-n-c;
    }
    .label {
      @extend %c-white-4;
      @extend %font-16;
    }
    .social {
      &-item {
        &:not(:last-child) {
          @extend %m-b-full;
        }
      }
      &-link {
        width: 3rem;
        aspect-ratio: 1;
        @extend %circle-radius;
        @extend %flex-c-c;
        @extend %c-blue-2-bg-10;
        @extend %c-pure-white-10;
        @extend %font-zero;
        &::before {
          font: 1.6rem $font-icon;
        }
      }
      &-email {
        .social-link::before {
          content: "\e80d";
        }
      }
      &-tel {
        .social-link::before {
          content: "\e85d";
        }
      }
    }
  }
  .social-links {
    .social {
      &-list {
        margin-block: var(--half-space) 3.5rem;
        @extend %flex-column;
        @extend %gap-full;
      }
      &-link {
        gap: 1rem;
        @extend %flex-n-c;
        @extend %c-blue-1-10;
        @extend %font-18;
        &::before {
          width: 3.8rem;
          aspect-ratio: 1;
          font: 2rem/1 $font-icon;
          @extend %circle-radius;
          @extend %flex-c-c;
          @extend %c-blue-2-bg-10;
          @extend %c-pure-white-10;
        }
      }
      &-facebook .social-link::before {
        content: "\E854";
      }
      &-instagram .social-link::before {
        content: "\E85E";
      }
      &-twitter .social-link::before {
        content: "\E861";
      }
    }
  }
  .two-col-layout {
    margin-bottom: 3.5rem;
    @extend %flex-column;
    @extend %gap-full;
    .profile-small {
      @extend %m-b-zero;
    }
  }
  .info-soon {
    padding-block: 4rem;
    border-radius: 1.2rem;
    border: .1rem solid var(--c-blue-light);
    @extend %c-ghost-white-bg-10;
    @extend %text-center;
    .image {
      width: 8rem;
      aspect-ratio: 1;
      margin-inline: auto;
      @extend %m-b-half;
      @extend %d-block;
    }
    .message {
      line-height: 1.33;
      @extend %c-blue-10;
      @extend %font-24;
    }
  }
  .note {
    line-height: 1.57;
    @extend %c-black-6;
  }
  .third-title-bold {
    margin-block: 0 2rem;
    line-height: 1.2;
    @extend %c-blue-10;
    @extend %font-20;
  }
  .fourth-title-bold {
    margin-block: 0 var(--one-n-half-space);
    line-height: 1.5;
    @extend %c-blue-2-10;
    @extend %font-14-pb;
  }
  address {
    margin-bottom: 3.5rem;
    font-style: normal;
    line-height: 1.5;
    @extend %c-black-6;
  }
  .wc-table {
    @extend %flag-label-styles;
    @extend %list-item-common;
    .item {
      flex-wrap: wrap;
      gap: 1.2rem;
      @extend %flex-sb-c;
    }
    .age-group {
      margin-block: 0;
      font-weight: 700;
      flex-basis: 100%;
      @extend %c-blue-10;
      @extend %font-16;
    }
    .periodicity {
      @extend %c-blue-1-10;
    }
    .meta-label {
      color: var(--c-blue-1);
    }
  }
  .webinar {
    &-list {
      gap: 3.5rem;
      @extend %flex-column;
      .image {
        object-fit: contain;
        @extend %h-100;
        @extend %m-b-full;
      }
      .link {
        font-weight: 700;
        line-height: 2;
        @extend %font-12;
        &::after {
          content: "";
          @include position-combo(inset);
        }
      }
    }
    &-item {
      aspect-ratio: 1.32;
      @extend %relative;
    }
  }
  .accordion-item {
    padding-inline: 0;
    margin-bottom: 0;
    .accordion {
      &-header {
        padding-block: 1.8rem;
        border-bottom: .1rem solid var(--c-blue-light);
        transition: margin-bottom .5s ease-in;
        @extend %w-100;
        @extend %flex-sb-c;
        @extend %gap-full;
        &::after {
          content: "\e802";
          font: 2.4rem/1 $font-icon;
          transition: rotate .5s ease-in;
          @extend %c-black-10;
        }
        &[aria-expanded='true'] {
          border-bottom: 0;
          @extend %m-b-full;
          &::after {
            rotate: 180deg;
          }
        }
      }
    }
    .btn-text {
      font-weight: 600;
      line-height: 1.2;
      @extend %c-blue-2-10;
      @extend %font-20;
    }
  }
  // Sponsors
  .sponsor {
    &-list {
      @extend %flex-column;
      @extend %gap-full;
    }
    &-item {
      aspect-ratio: 1.71;
      border: .1rem solid var(--c-blue-light);
      border-radius: var(--half-radius);
      @extend %w-100;
      @extend %flex-c-c;
      @extend %relative;
      @extend %c-blue-light-bg-4;
      &:hover {
        .sponsor-thumb {
          border-radius: var(--half-radius);
          display: block;
          object-fit: cover;
          @include position-combo(inset);
          @extend %h-100;
        }
        .sponsor-logo {
          position: relative;
          object-position: -10000rem;
        }
      }
      &.nike-wrestling:hover .sponsor-logo {
        background: url('/static-assets/images/client/white/nike-wrestling.svg?v=#{$image-version}') center/contain no-repeat;
      }
      &.taishan:hover .sponsor-logo {
        background: url('/static-assets/images/client/white/taishan.svg?v=#{$image-version}') center/contain no-repeat;
      }
      &.beograd:hover .sponsor-logo {
        background: url('/static-assets/images/client/white/beograd.svg?v=#{$image-version}') center/contain no-repeat;
      }
      &.uww:hover .sponsor-logo {
        background: url('/static-assets/images/client/white/uww.svg?v=#{$image-version}') center/contain no-repeat;
      }
    }
    &-thumb {
      @extend %d-none;
    }
    &-logo {
      max-width: 50%;
      width: auto;
      max-height: 58%;
      height: auto;
    }
    &-link {
      @include position-combo(inset);
      @extend %font-zero;
    }
  }
  // Styles for About Events: New static pages eg. Mutaraha
  .value {
    &-list:not(:last-child) {
      margin-bottom: 4.5rem;
    }
    &-item {
      @extend %m-b-one-n-half;
    }
    &-label {
      line-height: 1;
      @extend %c-blue-3-10;
      @extend %font-20;
    }
  }
  .step {
    &-list:not(:last-child) {
      margin-bottom: 4.5rem;
    }
    &-item {
      min-height: 8rem;
      @extend %p-full;
      @extend %half-radius;
      @extend %flex-n-c;
      @extend %c-light-grey-1-bg-4;
      @extend %gap-full;
      &:not(:last-child) {
        @extend %m-b-full;
      }
      &::before {
        counter-increment: steps;
        content: "0" counter(steps);
        font-family: $font-pb;
        font-weight: bold;
        @extend %c-blue-10;
        @extend %font-20-pb;
      }
    }
    &-label {
      @extend %c-blue-10;
    }
  }
  .card-horizontal {
    &-list {
      &-list:not(:last-child) {
        margin-bottom: 4.5rem;
      }
      .card {
        &-fig {
          @extend %m-b-full;
        }
        &-img {
          max-width: 14rem;
          aspect-ratio: 4/3;
        }
        &-title {
          margin-block: 0 1.2rem;
          font: 400 1.4rem/1 $font-pr;
          @extend %c-orange-10;
        }
        &-text {
          line-height: 1.5;
          @extend %c-blue-10;
        }
      }
    }
    &-item {
      padding: 1.2rem var(--full-space);
      border-radius: 1rem;
      border: .1rem solid hsl(var(--hsl-c-white) / .2);
      box-shadow: 0 .5rem 2rem 0 hsl(var(--hsl-c-dark-liver) / .1);
      @extend %m-b-full;
      @extend %c-white-bg-10;
    }
  }
  .uniform {
    &-wrap {
      margin-bottom: 4.5rem;
    }
    &-image-wrap:not(:last-child) {
      @extend %m-b-two-space;
    }
    &-title {
      margin-bottom: 2rem;
      font: 400 1rem/1 $font-pr;
      @extend %c-orange-10;
    }
  }
  .points {
    &-list:not(:last-child) {
      @extend %m-b-three-space;
    }
    &-item {
      list-style-type: ". ";
    }
    &-text {
      line-height: 2;
      @extend %c-blue-10;
      @extend %font-16;
    }
  }
  .podium {
    &-list {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      @extend %gap-full;
      &:not(:last-child) {
        @extend %m-b-two-space;
      }
    }
    &-item {
      padding: 2rem 1.2rem;
      gap: 1.2rem;
      @extend %flex-column;
      @extend %half-radius;
      @extend %c-light-grey-1-bg-4;
    }
    &-label,
    &-value {
      font-size: 1.6rem;
      line-height: 1;
      @extend %c-blue-10;
    }
    &-label {
      font-family: $font-pb;
      font-weight: 700;
    }
  }
  .main-logo {
    &-wrap {
      padding: var(--two-space) var(--full-space);
    }
    &-img {
      max-width: 24.3rem;
    }
  }
  // Styles for cards in Beach Wrestling page
  .card {
    padding: var(--one-n-half-space) var(--full-space);
    border-radius: 1rem;
    border: .1rem solid hsl(var(--hsl-c-white) / .2);
    box-shadow: 0 .5rem 2.063rem 0 hsl(var(--hsl-c-dark-liver) / .1);
    @extend %c-white-bg-10;
    &:not(:last-child) {
      @extend %m-b-full;
    }
    .card {
      &-note {
        margin-bottom: 1.2rem;
        @extend %d-block;
        @extend %c-black-6;
        @extend %font-14-pr;
      }
      &-title {
        margin-block: 0 1rem;
        @extend %c-orange-10;
        @extend %font-14-pr;
      }
      &-subtitle {
        margin-block: 0;
        @extend %c-blue-10;
        @extend %font-14-pb;
      }
    }
    .list-item::marker,
    .list-text,
    .body-2 {
      @extend %c-blue-10;
    }
    .body-2:not(:last-child) {
      margin-bottom: 3rem;
    }
    > *:last-child {
      @extend %m-b-zero;
    }
  }
  // Style for image list
  .image-list {
    margin-bottom: 6rem;
    @include card-count-new(1.15, var(--full-space));
    @extend %p-r-full;
    @extend %m-r-full-neg;
  }
  // Utility classes
  .content {
    .mb-zero {
      margin-bottom: 0;
    }
    .mb-half {
      margin-bottom: var(--half-space);
    }
    .mb-full {
      margin-bottom: var(--full-space);
    }
    .mb-one-n-half {
      margin-bottom: var(--one-n-half-space);
    }
    .mb-normal {
      margin-bottom: 3.5rem;
    }
    .mb-title {
      margin-bottom: 4.5rem;
    }
    .mb-60 {
      margin-bottom: 6rem;
    }
    .mt-normal {
      margin-top: 3.5rem;
    }
    .mt-60 {
      margin-top: 6rem;
    }
    .pl-zero {
      padding-left: 0;
    }
  }
  .orange {
    color: var(--c-orange);
  }
  .normal {
    font-family: $font-pr;
  }
  .body-color {
    color: hsl(var(--hsl-c-black) / .6);
  }
  .c-blue {
    color: var(--c-blue);
  }
  .c-blue-1 {
    color: var(--c-blue-1);
  }
  .c-blue-2 {
    color: var(--c-blue-2);
  }
  .underline {
    text-decoration: underline;
  }
  .bold {
    font-family: $font-pb;
  }
  .gap-0 {
    gap: 0;
  }
  // Styles for Table of About Events: Wrestling Styles pages
  .table-about-events {
    overflow-x: auto;
    &:not(:last-child) {
      @extend %m-b-two-space;
    }
    tr:first-child th {
      @extend %c-blue-light-bg-3;
    }
    th {
      @extend %text-left;
    }
    th,
    td {
      border: .1rem solid hsl(var(--hsl-c-black) / 0.1);
      white-space: nowrap;
      @extend %p-half;
    }
  }
}
.waf-inside-default .title {
  margin-bottom: var(--two-space);
  font: 4.2rem/1 $font-sb;
  line-height: 1.2;
}
@media screen and (min-width: $tablet-min-width) {
  .waf-news-listing {
    padding-bottom: 12.2rem;
    .layout-wrapper {
      max-width: 100%;
    }
    .article {
      &-list {
        flex-direction: column;
        flex-wrap: unset;
        gap: 1.2rem;
      }
      &-item {
        width: 100%;
      }
    }
    .second-list {
      margin-top: 1.2rem;
    }
  }
  .waf-uww-inside-page {
    padding-block: 8rem;
    .layout-wrapper {
      max-width: unset;
      padding-inline: 0;
    }
  }
  .waf-inside-common {
    .eyebrow {
      font-size: 1.6rem;
    }
    .body {
      font-size: 1.6rem;
      line-height: 1.5;
      .link,
      .link-normal {
        font-size: 1.6rem;
      }
    }
    .body-2 {
      font-size: 1.6rem;
    }
    .bullet-list {
      .link {
        font-size: 1.6rem;
      }
    }
    .list-text,
    .list-title {
      font-size: 1.6rem;
      line-height: 1.5;
    }
    .ol-list-item,
    .ol-list-text {
      font-size: 1.6rem;
    }
    .title {
      font-size: 3.6rem;
      line-height: 1.22;
    }
    .subtitle-bold.space {
      padding-top: 5rem;
    }
    .download-pdf {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      .file {
        gap: var(--full-space);
      }
      .file-name {
        font-size: 1.6rem;
        align-items: center;
      }
      .btn-download {
        min-width: 15rem;
        font-size: 1.6rem;
        margin-left: 0;
      }
    }
    .main-link {
      margin-bottom: var(--one-n-half-space);
      .link {
        font-size: 1.6rem;
      }
    }
    .two-images {
      flex-direction: row;
      flex-wrap: wrap;
      > * {
        width: calc((100% - var(--one-n-half-space)) / 2);
      }
    }
    .video .video-title {
      bottom: 2.4rem;
      left: 4rem;
    }
    .one-image-portrait .image {
      width: 36rem;
      height: 50rem;
    }
    .card-job {
      padding: 6.5rem 6.4rem;
      margin-bottom: 3.5rem;
      .card {
        &-flag-wrap {
          gap: var(--full-space);
        }
        &-flag-label {
          font-size: 2.4rem;
        }
        &-title {
          font-size: 3.2rem;
        }
        &-content {
          font-size: 1.6rem;
        }
      }
    }
    .card-mat {
      padding: 4.5rem 3.5rem 3.7rem;
      display: flex;
      gap: 2rem;
      .card {
        &-logo-wrap {
          max-width: 25rem;
          width: 35%;
          margin-bottom: 0;
        }
        &-logo-img {
          aspect-ratio: 2.12;
          object-fit: unset;
        }
        &-content {
          flex-grow: 1;
        }
        &-info {
          font-size: 1.6rem;
        }
        &-meta-list {
          padding-top: 1.8rem;
        }
        &-meta-row {
          display: flex;
          &:first-child {
            margin-bottom: 1rem;
          }
        }
        &-meta {
          padding-block: 0;
          border-bottom: 0;
          &:first-child {
            border-right: .1rem solid var(--c-light-grey-1);
            padding-right: 3rem;
          }
          &:nth-child(2) {
            padding-left: 3rem;
          }
        }
      }
    }
    .profile-big {
      .card {
        &-pic {
          width: 13rem;
          min-width: 13rem;
        }
        &-title {
          font-size: 2rem;
        }
      }
    }
    .profile-small {
      .card {
        &-pic {
          width: 6.5rem;
          min-width: 6.5rem;
        }
        &-content {
          width: calc(100% - var(--full-space) - 6.5rem);
        }
        &-flag {
          width: 2rem;
          min-width: 2rem;
        }
        &-name {
          font-size: 1.8rem;
        }
      }
    }
    .card-contact {
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1.2rem;
      .card-item {
        width: calc((100% - 1.2rem) / 2);
        padding-block: var(--full-space);
      }
    }
    .header-profile {
      margin-bottom: 4.5rem;
      gap: 3.5rem;
      .card {
        &-pic {
          width: 18rem;
          min-width: 18rem;
          height: 18rem;
        }
        &-name {
          font-size: 3.6rem;
        }
        &-label {
          font-size: 2rem;
        }
      }
    }
    .header-title {
      font-size: 3.6rem;
      line-height: 1.22;
    }
    .header-fed {
      margin-block: 0 6rem;
      gap: 3.5rem;
      .card {
        &-flag {
          width: 8.5rem;
          min-width: 8.5rem;
        }
        &-name {
          font-size: 3.6rem;
          line-height: 1.22;
        }
      }
    }
    .national-federations,
    .courses-list,
    .federations-list,
    .tech-assistance-list,
    .councils-list,
    .documents-list,
    .training-education,
    .member-banner {
      margin-inline: 0;
    }
    .national-federations {
      margin-bottom: 4.5rem;
      .item {
        padding-inline: 2rem;
        align-items: center;
        justify-content: unset;
        grid-template-areas: 'a b c';
        grid-template-columns: 31% auto 17%;
        &-country {
          font-size: 1.6rem;
        }
        &-flag {
          gap: var(--full-space);
        }
        &-flag-img {
          width: 3.5rem;
          min-width: 3.5rem;
        }
        &.header {
          display: grid;
        }
      }
    }
    .courses-list {
      .item {
        padding-block: 2rem;
        display: flex;
        align-items: center;
        > * {
          padding-right: 0;
        }
        > {
          :first-child {
            width: 9%;
            order: -2;
          }
          :nth-child(2) {
            order: 0;
          }
          :nth-child(3) {
            order: -1;
          }
          :nth-child(2),
          :nth-child(3) {
            width: 23%;
          }
          :nth-child(4),
          :nth-child(5),
          :nth-child(6) {
            width: 15%;
          }
        }
      }
      .meta,
      .value {
        &::before {
          content: unset;
        }
      }
      .tournament {
        font-size: 1.6rem
      }
    }
    .federations-list {
      .item {
        padding-inline: 2rem;
        @include flex-config(flex, null, null, center);
        > {
          :first-child {
            width: 70%;
          }
          :nth-child(2) {
            width: 30%;
          }
        }
      }
    }
    .tech-assistance-list {
      .item {
        padding-inline: var(--full-space);
        flex-wrap: nowrap;
        > {
          :first-child {
            width: 22.5%;
          }
          :nth-child(2) {
            width: 30%;
          }
          :nth-child(3) {
            width: 27.6%;
          }
          :nth-child(4) {
            width: 19.9%;
          }
        }
      }
      .competition {
        flex-basis: unset;
      }
      .value:last-child {
        content: unset;
      }
    }
    .councils-list {
      .item {
        padding-inline: var(--full-space);
        font-size: 1.6rem;
        flex-wrap: nowrap;
        justify-content: unset;
        > {
          :first-child,
          :nth-child(2) {
            width: 40%;
          }
          :nth-child(3) {
            width: 20%;
          }
        }
      }
      .member {
        &-meta {
          flex-basis: unset;
          gap: var(--full-space);
          @include flex-config(flex, null, null, center);
        }
        &-img {
          position: unset;
        }
      }
    }
    .documents-list {
      .item {
        display: flex;
        > {
          * {
            padding-left: var(--full-space);
          }
          :first-child {
            width: 70%;
          }
          :nth-child(2) {
            width: 30%;
          }
        }
      }
      .btn-download {
        text-align: left;
      }
      .name {
        margin-bottom: 0;
      }
    }
    .tournaments-list {
      margin-bottom: 4.5rem;
      .item {
        align-items: center;
        grid-template-areas: 'a b c';
        grid-template-columns: 3.7rem auto max-content;
        &:not(:first-child) {
          padding-left: var(--full-space);
        }
        &:not(:last-child) {
          margin-bottom: var(--full-space);
        }
      }
      .meta-flag {
        width: 1.6rem;
        min-width: 1.6rem;
      }
    }
    .training-education {
      margin-bottom: 4.5rem;
      .item {
        align-items: center;
        > {
          :first-child {
            width: 9%;
            text-align: center;
          }
          :nth-child(2) {
            width: 14%;
          }
          :nth-child(3) {
            width: 29%;
          }
          :nth-child(4) {
            width: 15%;
          }
          :nth-child(5) {
            width: 11%;
          }
          :nth-child(6) {
            width: 10%;
          }
          :nth-child(7) {
            width: 12%;
          }
        }
      }
      .course {
        flex-basis: unset;
      }
      .meta,
      .value {
        &::before {
          content: unset;
        }
      }
    }
    .member-banner {
      max-width: 100%;
      min-height: 49.7rem;
      aspect-ratio: 2.88;
      display: flex;
      flex-direction: row-reverse;
      .banner {
        &-head {
          width: 59.5%;
          &::after {
            width: 55%;
            background: linear-gradient(90deg, hsl(var(--hsl-c-blue) / 1), hsl(var(--hsl-c-blue) / 0));
          }
        }
        &-thumb {
          height: 100%;
          aspect-ratio: unset;
        }
        &-body {
          width: 40.5%;
          height: unset;
          margin-top: 0;
          padding-left: 4rem;
        }
        &-footer {
          top: 12.4rem;
          right: 4rem;
          bottom: unset;
        }
      }
      .position {
        &-list {
          margin-bottom: var(--two-space);
        }
        &-item:not(:last-child) {
          margin-bottom: .4rem;
        }
      }
      .item {
        gap: 6rem;
      }
    }
    .social-links {
      .social-list {
        flex-direction: row;
        justify-content: space-between;
      }
    }
    .fourth-title-bold {
      font-size: 1.6rem;
    }
    address {
      font-size: 1.6rem;
    }
    .wc-table {
      .item {
        background: hsl(var(--hsl-c-light-grey-1) / .2);
        gap: 0;
        &:hover {
          background: hsl(var(--hsl-c-bright-gray) / .8);
        }
        &:not(.header) {
          border-bottom: .1rem solid var(--c-light-grey-1);
        }
        > * {
          &:first-child {
            width: 41%;
          }
          &:nth-child(2) {
            width: 27%;
          }
          &:nth-child(3) {
            width: 32%;
          }
        }
      }
      .age-group {
        flex-basis: unset;
      }
    }
    .webinar {
      &-list {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 3.5rem var(--one-n-half-space);
      }
      &-item {
        width: calc((100% - var(--one-n-half-space)) / 2);
      }
    }
    .sponsor {
      &-list {
        flex-direction: row;
        flex-wrap: wrap;
      }
      &-item {
        flex-basis: calc((100% - var(--one-n-half-space)) / 2);
        gap: var(--one-n-half-space);
      }
    }
    // Styles for About Events: New static pages eg. Mutaraha
    .value-label {
      font-size: 2.4rem;
    }
    .step {
      &-item {
        padding-left: 2rem;
        gap: var(--one-n-half-space);
        &::before {
          font-size: 2.4rem
        }
      }
      &-label {
        font-size: 1.6rem;
      }
    }
    .card-horizontal {
      &-list .card {
        &-fig {
          margin-bottom: 0;
          align-self: flex-start;
        }
        &-text {
          font-size: 1.6rem;
        }
      }
      &-item {
        display: grid;
        align-items: center;
        gap: var(--full-space);
        grid-template-columns: 14rem auto;
      }
    }
    .uniform {
      &-wrap {
        display: grid;
        gap: 2.1rem;
        grid-template-columns: 37.5% auto;
      }
      &-image-wrap:not(:last-child) {
        padding-right: 2.1rem;
        border-right: .1rem solid var(--c-blue-light);
        margin-bottom: 0;
      }
    }
    .podium {
      &-list {
        gap: 2rem;
      }
      &-item {
        flex-direction: row;
        justify-content: center;
      }
      &-label,
      &-value {
        font-size: 2.2rem;
      }
    }
    .main-logo-wrap {
      padding-inline: var(--two-space);
    }
    // Styles for cards in Beach Wrestling page
    .card {
      padding-block: var(--two-space);
      .card-subtitle {
        font-size: 1.6rem;
      }
    }
    // Style for image list
    .image-list {
      padding-right: 0;
      margin-right: 0;
      overflow-x: unset;
      .image-item {
        width: unset;
        flex: 1;
      }
    }
    // Utility classes
    .content {
      .pt-three {
        padding-top: var(--three-space);
      }
      .mt-three {
        margin-top: var(--three-space);
      }
    }
    // About Events: Wrestling Styles table styles
    .table-about-events {
      th,
      td {
        white-space: unset;
      }
    }
  }
  .waf-inside-default {
    .title {
      margin-bottom: 4rem;
      font-size: 6.4rem;
      line-height: 1.3;
    }
  }
}
@media screen and (min-width: $desktop-min-width) {
  .waf-inside-common {
    .card-contact {
      .card-item {
        width: calc((100% - 2 * 1.2rem) / 3);
      }
      &.two-col .card-item {
        width: calc((100% - 1.2rem) / 2);
      }
    }
    .training-education .item > * {
      padding-right: var(--full-space);
    }
    .two-col-layout {
      flex-direction: row;
      flex-wrap: wrap;
      gap: var(--one-n-half-space);
      .item {
        flex-basis: calc((100% - var(--one-n-half-space)) / 2);
      }
    }
    .member-banner {
      .banner-body {
        padding-left: var(--container-white-space);
      }
      .name {
        margin-bottom: var(--full-space);
        font-size: 6.4rem;
        line-height: 1.3;
      }
      .label,
      .meta-label {
        font-size: 1.8rem;
      }
      .position-item {
        font-size: 1.6rem;
      }
    }
  }
}